.card__body {
  --scrollbarBG:rgba(27, 27, 27, 0.9);
  --thumbBG: #a8910f;
}
.card__body::-webkit-scrollbar {
  width: 11px;
}
.card__body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.card__body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.card__body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  background-color: #222;
  text-transform: none !important;
}

@media (min-width: 768px) {
  body {
    font-size: 20px;
  }
}

a {
  color: none;
  text-decoration: none;
}

p {
  padding: 0px;
  margin: 0px;
}

#app-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: 5em;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--star-background);
  transition: opacity 250ms ease-in;
  z-index: 500;
}

.cover.hide {
  opacity: 0;
  pointer-events: none;
}

#timer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  background-color: lightyellow;
  opacity: 0.9;
  display: inline-block;
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 20px;
  pointer-events: none;
}

#timer > p {
  margin-bottom: 10px;
}

.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 400ms;
  color: white;
  background-position: center;
  background-size: cover;
  background-color: #222;
}

.page.open {
  opacity: 1;
  pointer-events: all;
}

.page__background-darken {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #222;
}

.modal-page {
  transform: translateY(100%);
  background-color: black;
  opacity: 1;
}

.modal-page > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-page.open {
  transform: translateY(0);
}

#auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-step {
  position: absolute;
  top: 40%;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 400ms;
}

.phone-number-container.hide {
  transform: translateX(-100%);
  opacity: 0;
}

.auth-step > p {
  margin-bottom: 1em;
}

.auth-step > input {
  height: 1.5em;
  font-size: 1.2em;
  text-align: center;
  width: 180px;
  padding: 10px;
}

.error-text {
  margin-top: -0.9em;
  color: #ff3f3f;
  font-size: 0.85em;
}

.verification-container.hide {
  transform: translateX(100%);
  opacity: 0;
}

.verification-message {
  margin-top: 1.3em;
}

.verification-try-again__get {
  margin-top: 0.2em;
  font-size: 0.85em;
  text-decoration: underline;
}

.verified-text {
  margin-top: -0.9em;
  color: #25bf25;
  font-size: 0.85em;
}

#home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.star-background {
  background-color: var(--star-background);
}

.stars {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: none;
}

.home-page__logo {
  max-height: 30%;
  width: 120%;
  object-fit: contain;
  margin-top: 10vh;
  flex-shrink: 0;
  z-index: 2;
}

.home-page__listening {
  position: absolute;
  bottom: 0px;
  background-color: var(--star-background);
  width: 100%;
  text-align: center;
  transform: translateY(100%) scale(0.4);
  transition: all 400ms;
  z-index: 0;
}

.home-page__listening.open {
  transform: translateY(0%) scale(1);
}

.home-page__listening > p:first-child {
  font-size: 1.8em;
  margin-bottom: 1em;
}

.home-page-spinner-container {
  width: 100%;
  display: flex;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 50%;
}

.spinner-x {
  position: absolute;
  right: 12%;
  width: 20px;
}

.homepage__buttons {
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 400ms 200ms;
  position: relative;
}

.homepage__buttons.hide {
  opacity: 0;
  transition: all 400ms;
  pointer-events: none;
}

.logo {
  align-self: center;
}

.button-big {
  color: white;
  margin: 20px;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid white;

  width: 180px;
  font-size: 1.5em;
  text-align: center;
  text-transform: uppercase;
}

.button-big .help-text {
  font-size: 0.8rem;
  margin-top: -5px;
  margin-bottom: 2px;
  text-transform: none;
}

.button-big.help-text {
  font-size: 1.2em;
  height: 28px;
}

.button-big.help-text.listening {
  display: flex;
  align-items: center;
}

.button-big__spinner {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

/* the spinner */
.button-big__spinner > :first-child {
  height: 40px;
}

/* the close button */
.button-big__spinner > :last-child {
  right: -5%;
  width: 1em;
}

.sync-button-text {
  flex: 3;
}

.button-internal {
  position: absolute;
  bottom: 0px;
  border: 1px solid white;
  padding: 4px 15px;
  font-size: 0.8rem;
  opacity: 0.7;
}

.button-bigger {
  color: white;
  margin: 20px;
  padding: 10px;
  border: 2px solid white;

  width: 240px;
  font-size: 2.3em;
  text-align: center;
  text-transform: uppercase;
}

#player-page {
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.player-page__header {
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: black;
}

.player-page__sync-header {
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  position: relative;
}

.player-page__sync-header__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.player-page__sync-header__inner > * {
  flex: 1;
}

.sync-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sync-status-container > img {
  width: 80px;
}

.sync-status-container > p {
  margin-top: 0.5em;
}

.player-page__content {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  position: relative;
}

.player-content-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -6px; /* hide page-content padding */
  background-color: black;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;
  transition: all 400ms;
  opacity: 0;
}

.player-content-modal.open {
  opacity: 1;
  pointer-events: all;
}

.player-content-modal > :first-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.player-content-modal__content.scroll {
  height: 93%;
}

.player-content-modal__category {
  text-transform: uppercase;
  font-size: 1.15em;
}

.player-content-modal__close {
  display: flex;
  align-items: center;
}

.player-content-modal__close img {
  width: 30px;
  height: 30px;
  margin-left: 12px;
}

.modal-player-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: black;
}

.modal-player-container video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal-player-name {
  font-size: 1.15em;
  text-align: center;
}

.modal-actor-name {
  font-size: 1.6em;
  text-transform: uppercase;
}

.thumbnail-list-container {
  flex: 1.1;
  height: 100%;
  margin-right: 10px;
  position: relative;
}

.thumbnail-list-container.dark {
  background-color: var(--player-content-section-background);
}

.thumbnail-list {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
  box-sizing: border-box;
}

.thumbnail-list > :first-child {
  text-align: center;
  margin-bottom: 12px;
}

.show-more {
  text-align: center;
  margin-bottom: 1em;
}

.show-less {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  margin-top: auto;
  height: 68px;
}

.show-less > p {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.thumbnails {
  padding: 0px 0.5em;
}

.thumbnail {
  text-align: center;
  margin-bottom: 20px;
}

.thumbnail:last-child {
  margin-bottom: 55px;
}

.thumbnail__image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--app-yellow);
  font-size: 2.5em;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.thumbnail__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.thumbnail.selected .thumbnail__image {
  border: 2px solid white;
}

.thumbnail.selected p {
  color: var(--app-yellow);
}

.thumbnail__name {
  text-transform: uppercase;
  margin-top: 0.3em;
}

.thumbnail__character {
  color: #a5a5a5;
  font-style: italic;
}

/* For Feature List on PlayerPage */
.feature-list {
  flex: 2.5;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
  position: relative;
}

.feature-item {
  background-color: var(--player-content-section-background);
  margin-bottom: 10px;
  padding: 6px;
  padding-top: 10px;
}

.feature-item__category {
  text-transform: uppercase;
  font-size: 1.15em;
  margin-bottom: 6px;
}

/* For the Feature List on Category Page */
.feature-list-item {
  display: grid;
  grid-template-columns: 45% 55%;
  width: 100%;
  margin-bottom: 1em;
  opacity: 0.6;
  transition: all 400ms;
}

@media (max-width: 320px) {
  .feature-list-item {
    grid-template: none;
    grid-gap: 0.8em;
    margin-bottom: 2em;
  }
}

.feature-list-item.selected {
  opacity: 1;
}

.feature-list-item__poster {
  cursor: pointer;
  height: fit-content;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0); /* clear border so we can add an 'outline' without increasing size */
}

.feature-list-item.selected .feature-list-item__poster {
  border: 2px solid white;
}

.feature-list-item__poster > img {
  width: 100%;
  display: block; /* hide the extra line-height below the image in its container, imgs are inline by default */
}

.feature-list-item__info {
  padding-top: 0.1em;
  padding-left: 0.5em;
}

.feature-list-item__info-name {
  margin-bottom: 0.25em;
}

.feature-list-item__info-description {
  opacity: 0.8;
  font-size: 0.85em;
}

.feature-list-item.selected .feature-list-item__info-name {
  color: var(--app-yellow);
}

.clip-poster {
  position: relative;
  margin-bottom: 0.5em;
}

.clip-poster__image {
  width: 100%;
}

.clip-poster__play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
}

.clip-poster__play.large {
  width: 60px;
}

.feature-list-item.selected .clip-poster__play {
  display: none;
}

.clip-poster__info {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px;
  text-transform: uppercase;
  font-size: 0.8em;
  min-width: 48px;
  text-align: center;
}

.page-body {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-color: #e7b74f black;
}

.card__body {
  scrollbar-color: #e7b74f black;
}
.main-player {
  width: 100vw;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.main-player video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-player__back-button {
  position: absolute;
  top: 16px;
  left: 16px;
}

.test-seekbar-header {
  width: 100vw;
  height: 84px;
  position: relative;
}

.button-home {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: white;
  height: 1.2em;
  font-size: 0.95em;
  padding: 1em;
  margin-top: 1em;
  z-index: 1;
}

.button-home > img {
  height: 100%;
  margin-right: 6px;
}

.modal {
  position: absolute;
  top: 50%;
  transform: translateY(50vh); /* Default offscreen bottom */
  transition: all 600ms;
  background-color: white;
  color: black;

  width: 90%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  text-align: center;
  z-index: 5;
}

.modal.open {
  opacity: 1;
  transform: translateY(-50%);
}

.modal__header {
  font-size: 1.6em;
  margin-bottom: 0.6em;
}

.movie-button {
  width: 100%;
  border: 2px solid black;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.modal__button {
  cursor: pointer;
  margin-top: 0.8em;
}

.back-button-container {
  padding: 16px;
}

.background-img {
  background-repeat: no-repeat;
  background-position: center;
}

.text-change-transition p {
  transition: all 300ms;
  position: absolute;
}

.text-change-transition p.last {
  opacity: 0;
}

.text-change-transition p.new {
  opacity: 1;
  transition-delay: 300ms;
}

.player__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  height: 85px;
  width: 85px;
  display: flex;
  border-radius: 8px;
}

.player__loading > img {
  width: 75px;
  margin: auto;
}

.player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-size: 80px;
}

.player-click-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Fade animation with ReactTransitionGroup */
.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in;
}
.page-header__back {
  position: absolute;
  top: 0px;
  margin-top: 20px;
  left: 0px;
  margin-left: 15px;
  z-index: 999;
}
.no-result-found {
  width: 100%;
  display: flex;
  justify-content: center;
}
