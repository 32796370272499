body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.back-button {
  flex: 1 1;
  height: 1.3em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-button img {
  height: 100%;
  margin-right: 0.4em;
}

.Extras {
  position: absolute;
  top: 12px;
  right: 5px;
  z-index: 111;
}
.page-header {
  width: 100vw;
  height: 70px;
  display: grid;
  grid-template-columns: minmax(1px, 1fr) minmax(1px, 2fr) minmax(1px, 1fr);
  grid-template-areas: "back logo page-name";
  grid-gap: 0.3em;
  align-items: center;
  padding: 15px 20px 0px 20px;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
}

.page-header__title {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 1.3em;
  text-transform: uppercase;
  grid-area: logo;
}

.page-header__logo {
  width: 100%;
  height: 100%;
  flex: 2 1;
  object-fit: contain;
  margin: 0px auto;
  margin-top: -0.4em;
  grid-area: logo;
}

.page-header__name {
  flex: 1 1;
  text-align: right;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: page-name;
  position: absolute;
  top: 10px;
  right: 2px;
}

.card {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(1px, 1fr) minmax(1px, 1fr); /* Safari doesn't like raw fr values, need to run a calculation */
  grid-gap: 1em;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}

.card__header {
  grid-area: header;
  display: grid;
  grid-template-areas:
    "headerImage"
    "headerInfo";
  grid-template-rows: minmax(1px, 3fr) minmax(1px, 1fr);
  grid-gap: 1em;
  height: 100%;
}

.card__header__image {
  grid-area: headerImage;
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.card__header__info {
  grid-area: headerInfo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-info__title {
  margin-bottom: 0.2em;
}

.card-info__subtitle {
  font-style: italic;
  font-size: 0.9em;
  opacity: 0.7;
}

.card__body {
  grid-area: body;
  font-size: 0.9em;
  line-height: 1.4em;
  position: relative;
  text-transform: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}

#actors-page {
  display: flex;
  flex-direction: column;
}

.actors-page-content {
  display: flex;
  height: 100%;
}

.actors-page-content__list {
  flex: 1.4 1;
}

.actors-page-content__detail {
  flex: 2.5 1;
  background-color: black;
  margin-left: -10px;
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 1.5em;
}

.card-grid {
  display: grid;
  padding: 1em;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  grid-auto-rows: 12rem;
  grid-gap: 1em;
  grid-auto-flow: dense;
  overflow-y: scroll;
}

.cast-member-card {
  background-color: rgba(27, 27, 27, 0.9);
  padding: 1em;
  border-radius: 0.2em;
  box-sizing: border-box;
  display: grid;
  grid-template-areas: "header";
  grid-template-rows: 100%;
  grid-template-columns: 100%;
}

.detail-card {
  grid-row: span 2;
  grid-column: span 2;
  overflow: hidden;
}

.detail-card .cast-member-card {
  grid-template-areas:
    "header"
    "body";
  grid-template-rows: minmax(1px, 2fr) minmax(1px, 3fr);
}

.detail-card .card__header {
  width: 75%;
  margin: auto;
  grid-template-areas: "headerImage headerInfo";
  grid-template-columns: minmax(1px, 1fr) minmax(1px, 2fr);
  grid-template-rows: minmax(1px, 1fr);
}

.detail-card .card-info__title {
  font-size: 1.2em;
}

.simple-detail-card {
  grid-template-areas:
    "header"
    "body";
  grid-template-rows: minmax(5em, 1fr) minmax(1px, 2fr);
}

.simple-detail-card .card__header {
  grid-template-areas: "headerImage";
  grid-template-rows: minmax(1px, 1fr);
}

.simple-detail-card .card__body {
  font-size: 1rem;
}

.swiper-container {
  z-index: 1;
  height: 100%;
}

.swiper-wrapper {
  z-index: -1;
  -webkit-transform-style: flat;
          transform-style: flat;
}

.swiper-slide.in-fullscreen {
  justify-content: center;
  display: flex;
  align-items: center;
}

.gallery-image {
  width: 100%;
}

.toolbar-shadow {
  position: absolute;
  bottom: -2px !important;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.6);
}

.fullscreen-btn {
  position: absolute;
  bottom: 0px;
  height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 0;
  font-size: 0.95em;
  padding-right: 0.6em;
  z-index: 9;
  cursor: pointer;
}
.fullscreen-btn img {
  width: 26px;
  height: 26px;
}
.fullscreen-btn:hover img {
  opacity: 0.9;
}

.swiper-pagination {
  width: 100%;
}

.swiper-pagination-fraction {
  bottom: -2px !important; /* override swiper.js default */
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 1.1em;
  padding-bottom: 1px;
  font-size: 0.95em;
  width: auto;
  width: initial;
}

.fullscreen-container .swiper-bullets-container {
  display: none;
}

.swiper-bullets-container {
  height: 20px;
  margin-top: 12px;
}

.swiper-pagination-bullet {
  height: 7px !important;
  width: 7px !important;
  background-color: #484848 !important;
  opacity: 1 !important;
  margin-right: 7px;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-button-prev.swiper-button--show,
.swiper-button-next.swiper-button--show {
  display: block;
}

.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: none;
}

.swiper-button-prev {
  left: 1em;
}

.swiper-button-next {
  right: 1em;
}

/* https://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari/23083463#23083463 
   By default, mobile Safari overrides width: 100% for iframes – theoretically due to lack of scrolling? – this fixes that
*/
.responsive-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.responsive-iframe-container iframe {
  width: 1px;
  min-width: 100%;
  height: 1px;
  min-height: 100%;
  border: none;
}

.iframe-poster {
  display: flex;
  width: 100%;
}

.iframe-poster__poster {
  width: 100%;
  align-self: center;
}

.iframe-poster__play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 60px;
  width: 60px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
}

.iframe-poster:hover .iframe-poster__play {
  opacity: 0.85;
  -webkit-transition: all 0s;
  transition: all 0s;
}

.responsive-iframe-container > p {
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.interactives-toolbar-holder {
  position: relative;
  width: 100%;
  height: 36px;
}

.toolbar-shadow {
  position: absolute;
  bottom: -2px !important;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.6);
}
.interactives-toolbar-holder .toolbar-shadow {
  bottom: 0 !important;
}

.fullscreen-btn {
  position: absolute;
  bottom: 0px;
  height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 0;
  font-size: 0.95em;
  padding-right: 0.6em;
  z-index: 9;
  cursor: pointer;
}
.interactives-toolbar-holder .fullscreen-btn {
  bottom: 1px;
}
.fullscreen-btn img {
  width: 26px;
  height: 26px;
}
.fullscreen-btn:hover img {
  opacity: 0.9;
}

.category-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#extras-page-scroll .extras-grid {
  scrollbar-color: #e7b74f black;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 36);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
/* Lay out side-by-side in wider aspect ratios */
@media (min-aspect-ratio: 5/4) {
  .category-page .page-body {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }

  .category-page__content {
    flex: 3 1;
  }

  .category-page__list {
    flex: 2 1;
  }
}

.category-page__content {
  width: 100%;
}

.category-page__main {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  background-color: black;
  position: relative;
  z-index: 1;
}

/* When in fullscreen, let abs positioning set the size */
.category-page__main--fs {
  padding: 0;
}

.category-page__main__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.category-page__detail {
  padding: 0.75em;
  height: 3em;
  text-align: center;
}

.category-page__detail__name {
  padding-top: 0.25em;
  font-size: 1.2em;
}

.category-page__list {
  display: grid;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  height: 100%;
  width: 100%;
  padding: 12px;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}

@media (min-width: 70em) {
  .category-page__list {
    grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  }
}

.vjs-time-control span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.video-react .video-react-big-play-button {
  position: center !important;
}

.scroll-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
}

.scroll-to-top img {
    width: 25px;
}

.background-blur-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.background-blur-image__blur {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-filter: blur(0.75rem);
          filter: blur(0.75rem);
}

.background-blur-image__main {
  position: relative;
  width: auto;
  height: 100%;
  object-fit: contain;
}

.extras-page {
  display: flex;
  flex-direction: column;
}
.extras-grid {
  --scrollbarBG:#222222;
  --thumbBG: #a8910f;
}
.extras-grid::-webkit-scrollbar {
  width: 11px;
}
.extras-grid {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.extras-grid::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.extras-grid::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.page-body {
  --scrollbarBG:#222222;
  --thumbBG: #a8910f;
}
.page-body::-webkit-scrollbar {
  width: 11px;
}
.page-body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.page-body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.page-body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.extras-grid {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-gap: 2em 1em;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.6em;
 
}

@media (min-width: 768px) {
  .extras-grid {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    padding: 1em;
    margin-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
   
  }
}

@media (min-width: 1400px) {
  .extras-grid {
    width: 80%;
    overflow-x: hidden;
    overflow-y: auto;
   
  }
}

.grid-item {
  text-transform: uppercase;
  overflow: hidden;
  height: 100%;
}

.grid-item > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
}

.grid-item__image {
  height: 0;
  padding-bottom: 56.25%;
  background-color: rgba(26, 26, 26, 0.8);
  margin-top: 0.2em;
  position: relative;
  text-align: center;
  overflow: hidden;
}

.grid-item__image__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.grid-item__image__img {
  width: 100%;
  cursor: pointer;
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
}

.error-page > img {
  width: 40%;
  min-width: 240px;
  margin-top: 1em;
}

#select-production-page {
  display: flex;
  flex-direction: column;
}
.search-bar {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  box-sizing: border-box;
}
.search-bar input {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid transparent;
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  color: white;
  box-sizing: border-box;
  -webkit-transition: border 250ms;
  transition: border 250ms;
  -webkit-transition: border var(--base-animation-duration, 250ms);
  transition: border var(--base-animation-duration, 250ms);
}
.search-bar input:focus {
  border: 1px solid rgba(255, 255, 255, 0.4);
  outline: none;
}
.production-list {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}
.grid-view{
  display: grid;
  overflow-y: scroll;
}
.production-list {
  --scrollbarBG:#222222;
  --thumbBG: #a8910f;
}
.production-list::-webkit-scrollbar {
  width: 11px;
}
.production-list {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.production-list::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.production-list::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.production-list-nbc {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  overflow-y: auto;
}

.production-list-item-wrapper {
  display: grid;
}
.production-list-item {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 1em 6.5px;
  padding: 1em 6.5px;
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.4);*/
  min-height: 70px;
  color: white;
}

.production-list-item-nbc {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 1em 6.5px;
  padding: 1em 6.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  min-height: 70px;
  color: white;
}
.production-list-item__image {
  align-self: center;
  min-width: 210px;
  max-width: 210px;
  height: 293px;
}
.production-list-item__image > img {
  width: 210px;
  height: 293px;
  object-fit: cover;
  display: block;
}

.production-list-item__image-nbc {
  align-self: center;
}
.production-list-item__image-nbc > img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.production-list-item__info-nbc {
  display: block;
  color: white;
}
.production-list-item__info {
  display: none;
}
.production-list-item__info :first-child {
  font-size: 1.3;
  margin-bottom: 0.4em;
}
.production-synopsis {
  overflow: hidden;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  opacity: 0.7;
  font-size: 0.9em;
}

.btn__scroll-left {
  position: fixed;
  margin-top: 9rem;
  font-size: 36px;
  background: #E1B747;
  padding: 18px 10px 12px 12px;
  color: black;
  border-radius: 50%;
  line-height: 0;
  height: 10px;
  left: 0px;
  cursor: pointer
}

.btn__scroll-right {
  position: fixed;
 margin-top: 9rem;
  font-size: 36px;
  background:#E1B747;
  padding: 18px 10px 12px 12px;
  color: black;
  border-radius: 50%;
  line-height: 0;
  height: 10px;
  right: 0px;
  cursor: pointer
}

.no-extras__container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 350px;
  margin: 5% auto;
}

.no-extras__box {
  border-radius: 10px;
  padding: 4rem;
  background: rgb(253, 230, 98);
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(236, 237, 113, 1)),
    color-stop(47%, rgba(219, 195, 57, 1)),
    to(rgba(209, 163, 26, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(236, 237, 113, 1) 0%,
    rgba(219, 195, 57, 1) 47%,
    rgba(209, 163, 26, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(236, 237, 113, 1) 0%,
    rgba(219, 195, 57, 1) 47%,
    rgba(209, 163, 26, 1) 100%
  );
}

.no-extras__text {
  color: rgba(34, 34, 34);
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .no-extras__box {
    padding: 2rem;
  }
  .no-extras__text {
    font-size: 1.4rem;
  }
}

.card__body {
  --scrollbarBG:rgba(27, 27, 27, 0.9);
  --thumbBG: #a8910f;
}
.card__body::-webkit-scrollbar {
  width: 11px;
}
.card__body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.card__body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.card__body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  background-color: #222;
  text-transform: none !important;
}

@media (min-width: 768px) {
  body {
    font-size: 20px;
  }
}

a {
  color: none;
  text-decoration: none;
}

p {
  padding: 0px;
  margin: 0px;
}

#app-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: 5em;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--star-background);
  -webkit-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
  z-index: 500;
}

.cover.hide {
  opacity: 0;
  pointer-events: none;
}

#timer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  background-color: lightyellow;
  opacity: 0.9;
  display: inline-block;
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 20px;
  pointer-events: none;
}

#timer > p {
  margin-bottom: 10px;
}

.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  color: white;
  background-position: center;
  background-size: cover;
  background-color: #222;
}

.page.open {
  opacity: 1;
  pointer-events: all;
}

.page__background-darken {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #222;
}

.modal-page {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  background-color: black;
  opacity: 1;
}

.modal-page > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-page.open {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

#auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-step {
  position: absolute;
  top: 40%;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

.phone-number-container.hide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
}

.auth-step > p {
  margin-bottom: 1em;
}

.auth-step > input {
  height: 1.5em;
  font-size: 1.2em;
  text-align: center;
  width: 180px;
  padding: 10px;
}

.error-text {
  margin-top: -0.9em;
  color: #ff3f3f;
  font-size: 0.85em;
}

.verification-container.hide {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
}

.verification-message {
  margin-top: 1.3em;
}

.verification-try-again__get {
  margin-top: 0.2em;
  font-size: 0.85em;
  text-decoration: underline;
}

.verified-text {
  margin-top: -0.9em;
  color: #25bf25;
  font-size: 0.85em;
}

#home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.star-background {
  background-color: var(--star-background);
}

.stars {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: none;
}

.home-page__logo {
  max-height: 30%;
  width: 120%;
  object-fit: contain;
  margin-top: 10vh;
  flex-shrink: 0;
  z-index: 2;
}

.home-page__listening {
  position: absolute;
  bottom: 0px;
  background-color: var(--star-background);
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(100%) scale(0.4);
          transform: translateY(100%) scale(0.4);
  -webkit-transition: all 400ms;
  transition: all 400ms;
  z-index: 0;
}

.home-page__listening.open {
  -webkit-transform: translateY(0%) scale(1);
          transform: translateY(0%) scale(1);
}

.home-page__listening > p:first-child {
  font-size: 1.8em;
  margin-bottom: 1em;
}

.home-page-spinner-container {
  width: 100%;
  display: flex;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 50%;
}

.spinner-x {
  position: absolute;
  right: 12%;
  width: 20px;
}

.homepage__buttons {
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transition: all 400ms 200ms;
  transition: all 400ms 200ms;
  position: relative;
}

.homepage__buttons.hide {
  opacity: 0;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  pointer-events: none;
}

.logo {
  align-self: center;
}

.button-big {
  color: white;
  margin: 20px;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid white;

  width: 180px;
  font-size: 1.5em;
  text-align: center;
  text-transform: uppercase;
}

.button-big .help-text {
  font-size: 0.8rem;
  margin-top: -5px;
  margin-bottom: 2px;
  text-transform: none;
}

.button-big.help-text {
  font-size: 1.2em;
  height: 28px;
}

.button-big.help-text.listening {
  display: flex;
  align-items: center;
}

.button-big__spinner {
  flex: 1 1;
  width: 100%;
  display: flex;
  align-items: center;
}

/* the spinner */
.button-big__spinner > :first-child {
  height: 40px;
}

/* the close button */
.button-big__spinner > :last-child {
  right: -5%;
  width: 1em;
}

.sync-button-text {
  flex: 3 1;
}

.button-internal {
  position: absolute;
  bottom: 0px;
  border: 1px solid white;
  padding: 4px 15px;
  font-size: 0.8rem;
  opacity: 0.7;
}

.button-bigger {
  color: white;
  margin: 20px;
  padding: 10px;
  border: 2px solid white;

  width: 240px;
  font-size: 2.3em;
  text-align: center;
  text-transform: uppercase;
}

#player-page {
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.player-page__header {
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: black;
}

.player-page__sync-header {
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  position: relative;
}

.player-page__sync-header__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.player-page__sync-header__inner > * {
  flex: 1 1;
}

.sync-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sync-status-container > img {
  width: 80px;
}

.sync-status-container > p {
  margin-top: 0.5em;
}

.player-page__content {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  position: relative;
}

.player-content-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -6px; /* hide page-content padding */
  background-color: black;
  padding: 10px;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  opacity: 0;
}

.player-content-modal.open {
  opacity: 1;
  pointer-events: all;
}

.player-content-modal > :first-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.player-content-modal__content.scroll {
  height: 93%;
}

.player-content-modal__category {
  text-transform: uppercase;
  font-size: 1.15em;
}

.player-content-modal__close {
  display: flex;
  align-items: center;
}

.player-content-modal__close img {
  width: 30px;
  height: 30px;
  margin-left: 12px;
}

.modal-player-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background-color: black;
}

.modal-player-container video {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.modal-player-name {
  font-size: 1.15em;
  text-align: center;
}

.modal-actor-name {
  font-size: 1.6em;
  text-transform: uppercase;
}

.thumbnail-list-container {
  flex: 1.1 1;
  height: 100%;
  margin-right: 10px;
  position: relative;
}

.thumbnail-list-container.dark {
  background-color: var(--player-content-section-background);
}

.thumbnail-list {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
  box-sizing: border-box;
}

.thumbnail-list > :first-child {
  text-align: center;
  margin-bottom: 12px;
}

.show-more {
  text-align: center;
  margin-bottom: 1em;
}

.show-less {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left bottom, left top,
    from(rgba(0, 0, 0, 1)),
    color-stop(55%, rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  margin-top: auto;
  height: 68px;
}

.show-less > p {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.thumbnails {
  padding: 0px 0.5em;
}

.thumbnail {
  text-align: center;
  margin-bottom: 20px;
}

.thumbnail:last-child {
  margin-bottom: 55px;
}

.thumbnail__image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--app-yellow);
  font-size: 2.5em;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.thumbnail__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.thumbnail.selected .thumbnail__image {
  border: 2px solid white;
}

.thumbnail.selected p {
  color: var(--app-yellow);
}

.thumbnail__name {
  text-transform: uppercase;
  margin-top: 0.3em;
}

.thumbnail__character {
  color: #a5a5a5;
  font-style: italic;
}

/* For Feature List on PlayerPage */
.feature-list {
  flex: 2.5 1;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
  position: relative;
}

.feature-item {
  background-color: var(--player-content-section-background);
  margin-bottom: 10px;
  padding: 6px;
  padding-top: 10px;
}

.feature-item__category {
  text-transform: uppercase;
  font-size: 1.15em;
  margin-bottom: 6px;
}

/* For the Feature List on Category Page */
.feature-list-item {
  display: grid;
  grid-template-columns: 45% 55%;
  width: 100%;
  margin-bottom: 1em;
  opacity: 0.6;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

@media (max-width: 320px) {
  .feature-list-item {
    grid-template: none;
    grid-gap: 0.8em;
    margin-bottom: 2em;
  }
}

.feature-list-item.selected {
  opacity: 1;
}

.feature-list-item__poster {
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0); /* clear border so we can add an 'outline' without increasing size */
}

.feature-list-item.selected .feature-list-item__poster {
  border: 2px solid white;
}

.feature-list-item__poster > img {
  width: 100%;
  display: block; /* hide the extra line-height below the image in its container, imgs are inline by default */
}

.feature-list-item__info {
  padding-top: 0.1em;
  padding-left: 0.5em;
}

.feature-list-item__info-name {
  margin-bottom: 0.25em;
}

.feature-list-item__info-description {
  opacity: 0.8;
  font-size: 0.85em;
}

.feature-list-item.selected .feature-list-item__info-name {
  color: var(--app-yellow);
}

.clip-poster {
  position: relative;
  margin-bottom: 0.5em;
}

.clip-poster__image {
  width: 100%;
}

.clip-poster__play {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 44px;
}

.clip-poster__play.large {
  width: 60px;
}

.feature-list-item.selected .clip-poster__play {
  display: none;
}

.clip-poster__info {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px;
  text-transform: uppercase;
  font-size: 0.8em;
  min-width: 48px;
  text-align: center;
}

.page-body {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-color: #e7b74f black;
}

.card__body {
  scrollbar-color: #e7b74f black;
}
.main-player {
  width: 100vw;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.main-player video {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.main-player__back-button {
  position: absolute;
  top: 16px;
  left: 16px;
}

.test-seekbar-header {
  width: 100vw;
  height: 84px;
  position: relative;
}

.button-home {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: white;
  height: 1.2em;
  font-size: 0.95em;
  padding: 1em;
  margin-top: 1em;
  z-index: 1;
}

.button-home > img {
  height: 100%;
  margin-right: 6px;
}

.modal {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(50vh);
          transform: translateY(50vh); /* Default offscreen bottom */
  -webkit-transition: all 600ms;
  transition: all 600ms;
  background-color: white;
  color: black;

  width: 90%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  text-align: center;
  z-index: 5;
}

.modal.open {
  opacity: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.modal__header {
  font-size: 1.6em;
  margin-bottom: 0.6em;
}

.movie-button {
  width: 100%;
  border: 2px solid black;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.modal__button {
  cursor: pointer;
  margin-top: 0.8em;
}

.back-button-container {
  padding: 16px;
}

.background-img {
  background-repeat: no-repeat;
  background-position: center;
}

.text-change-transition p {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  position: absolute;
}

.text-change-transition p.last {
  opacity: 0;
}

.text-change-transition p.new {
  opacity: 1;
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}

.player__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: black;
  height: 85px;
  width: 85px;
  display: flex;
  border-radius: 8px;
}

.player__loading > img {
  width: 75px;
  margin: auto;
}

.player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-size: 80px;
}

.player-click-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Fade animation with ReactTransitionGroup */
.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}
.page-header__back {
  position: absolute;
  top: 0px;
  margin-top: 20px;
  left: 0px;
  margin-left: 15px;
  z-index: 999;
}
.no-result-found {
  width: 100%;
  display: flex;
  justify-content: center;
}

