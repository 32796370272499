#select-production-page {
  display: flex;
  flex-direction: column;
}
.search-bar {
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  box-sizing: border-box;
}
.search-bar input {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid transparent;
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  color: white;
  box-sizing: border-box;
  transition: border var(--base-animation-duration, 250ms);
}
.search-bar input:focus {
  border: 1px solid rgba(255, 255, 255, 0.4);
  outline: none;
}
.production-list {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: min-content;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}
.grid-view{
  display: grid;
  overflow-y: scroll;
}
.production-list {
  --scrollbarBG:#222222;
  --thumbBG: #a8910f;
}
.production-list::-webkit-scrollbar {
  width: 11px;
}
.production-list {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.production-list::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.production-list::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.production-list-nbc {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: min-content;
  overflow-y: auto;
}

.production-list-item-wrapper {
  display: grid;
}
.production-list-item {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 1em 6.5px;
  padding: 1em 6.5px;
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.4);*/
  min-height: 70px;
  color: white;
}

.production-list-item-nbc {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 1em 6.5px;
  padding: 1em 6.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  min-height: 70px;
  color: white;
}
.production-list-item__image {
  align-self: center;
  min-width: 210px;
  max-width: 210px;
  height: 293px;
}
.production-list-item__image > img {
  width: 210px;
  height: 293px;
  object-fit: cover;
  display: block;
}

.production-list-item__image-nbc {
  align-self: center;
}
.production-list-item__image-nbc > img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.production-list-item__info-nbc {
  display: block;
  color: white;
}
.production-list-item__info {
  display: none;
}
.production-list-item__info :first-child {
  font-size: 1.3;
  margin-bottom: 0.4em;
}
.production-synopsis {
  overflow: hidden;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  opacity: 0.7;
  font-size: 0.9em;
}
