.card {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(1px, 1fr) minmax(1px, 1fr); /* Safari doesn't like raw fr values, need to run a calculation */
  grid-gap: 1em;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}

.card__header {
  grid-area: header;
  display: grid;
  grid-template-areas:
    "headerImage"
    "headerInfo";
  grid-template-rows: minmax(1px, 3fr) minmax(1px, 1fr);
  grid-gap: 1em;
  height: 100%;
}

.card__header__image {
  grid-area: headerImage;
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.card__header__info {
  grid-area: headerInfo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-info__title {
  margin-bottom: 0.2em;
}

.card-info__subtitle {
  font-style: italic;
  font-size: 0.9em;
  opacity: 0.7;
}

.card__body {
  grid-area: body;
  font-size: 0.9em;
  line-height: 1.4em;
  position: relative;
  text-transform: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Bouncy scroll on iOS */
}
