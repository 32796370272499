.btn__scroll-left {
  position: fixed;
  margin-top: 9rem;
  font-size: 36px;
  background: #E1B747;
  padding: 18px 10px 12px 12px;
  color: black;
  border-radius: 50%;
  line-height: 0;
  height: 10px;
  left: 0px;
  cursor: pointer
}

.btn__scroll-right {
  position: fixed;
 margin-top: 9rem;
  font-size: 36px;
  background:#E1B747;
  padding: 18px 10px 12px 12px;
  color: black;
  border-radius: 50%;
  line-height: 0;
  height: 10px;
  right: 0px;
  cursor: pointer
}
