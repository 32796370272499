.extras-page {
  display: flex;
  flex-direction: column;
}
.extras-grid {
  --scrollbarBG:#222222;
  --thumbBG: #a8910f;
}
.extras-grid::-webkit-scrollbar {
  width: 11px;
}
.extras-grid {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.extras-grid::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.extras-grid::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.page-body {
  --scrollbarBG:#222222;
  --thumbBG: #a8910f;
}
.page-body::-webkit-scrollbar {
  width: 11px;
}
.page-body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.page-body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.page-body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.extras-grid {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 2em 1em;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.6em;
 
}

@media (min-width: 768px) {
  .extras-grid {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    padding: 1em;
    margin-bottom: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
   
  }
}

@media (min-width: 1400px) {
  .extras-grid {
    width: 80%;
    overflow-x: hidden;
    overflow-y: auto;
   
  }
}

.grid-item {
  text-transform: uppercase;
  overflow: hidden;
  height: 100%;
}

.grid-item > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
}

.grid-item__image {
  height: 0;
  padding-bottom: 56.25%;
  background-color: rgba(26, 26, 26, 0.8);
  margin-top: 0.2em;
  position: relative;
  text-align: center;
  overflow: hidden;
}

.grid-item__image__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.grid-item__image__img {
  width: 100%;
  cursor: pointer;
}
