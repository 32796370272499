.background-blur-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.background-blur-image__blur {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  filter: blur(0.75rem);
}

.background-blur-image__main {
  position: relative;
  width: auto;
  height: 100%;
  object-fit: contain;
}
