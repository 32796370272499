.swiper-container {
  z-index: 1;
  height: 100%;
}

.swiper-wrapper {
  z-index: -1;
  transform-style: flat;
}

.swiper-slide.in-fullscreen {
  justify-content: center;
  display: flex;
  align-items: center;
}

.gallery-image {
  width: 100%;
}

.toolbar-shadow {
  position: absolute;
  bottom: -2px !important;
  left: 0;
  width: 100%;
  z-index: 9;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.6);
}

.fullscreen-btn {
  position: absolute;
  bottom: 0px;
  height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  right: 0;
  font-size: 0.95em;
  padding-right: 0.6em;
  z-index: 9;
  cursor: pointer;
}
.fullscreen-btn img {
  width: 26px;
  height: 26px;
}
.fullscreen-btn:hover img {
  opacity: 0.9;
}

.swiper-pagination {
  width: 100%;
}

.swiper-pagination-fraction {
  bottom: -2px !important; /* override swiper.js default */
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 1.1em;
  padding-bottom: 1px;
  font-size: 0.95em;
  width: initial;
}

.fullscreen-container .swiper-bullets-container {
  display: none;
}

.swiper-bullets-container {
  height: 20px;
  margin-top: 12px;
}

.swiper-pagination-bullet {
  height: 7px !important;
  width: 7px !important;
  background-color: #484848 !important;
  opacity: 1 !important;
  margin-right: 7px;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-button-prev.swiper-button--show,
.swiper-button-next.swiper-button--show {
  display: block;
}

.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: none;
}

.swiper-button-prev {
  left: 1em;
}

.swiper-button-next {
  right: 1em;
}
